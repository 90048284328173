import React from 'react';
import Expenses from './components/Expenses/Expenses';

const App = () => {
  const expenses = [
    { id: 'e1', title: 'Cena', amount: 120.0, date: new Date(2022, 9, 17) },
    {
      id: 'e2',
      title: 'Coca cola',
      amount: 16.5,
      date: new Date(2022, 9, 17),
    },
    { id: 'e3', title: 'Cigarros', amount: 63.0, date: new Date(2022, 9, 18) },
  ];
  return (
    <div className='App'>
      <Expenses items={expenses} />      
    </div>
  );
}

export default App;
