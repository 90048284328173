import React from 'react';
import "./ExpenseDate.css";

const ExpenseDate = (props) => {
  const dia = props.date.toLocaleString("es-MX", { day: "2-digit" });
  const mes = props.date.toLocaleString("es-MX", { month: "long" });
  const anio = props.date.getFullYear();

  return (
    <div className="expense-date">
      <div className="expense-date__month">{mes}</div>
      <div className="expense-date__year">{anio}</div>
      <div className="expense-date__day">{dia}</div>
      {/* {props.date.toDateString()}  */}
    </div>
  );
}

export default ExpenseDate;
